import { createQueryKeys } from "@lukemorales/query-key-factory";

export const applicationsQueryKeys = createQueryKeys("applications", {
  fetchApplication: () => ["fetchApplication"],
  fetchImageDocument: applicationId => ["fetchImageDocument", applicationId],
  updateIdentification: (applicationId: number) => [
    "updateIdentification",
    applicationId,
  ],
  updateApplicationNew: (applicationId: number) => [
    "updateApplicationNew",
    applicationId,
  ],
  updateApplication: (applicationId: number) => [
    "updateApplication",
    applicationId,
  ],
  updateBusinessLicense: (applicationId: number) => [
    "updateBusinessLicense",
    applicationId,
  ],
  updateDepositAccount: (applicationId: number) => [
    "updateDepositAccount",
    applicationId,
  ],
  updateDocumentImage: (applicationId: number) => [
    "updateDocumentImage",
    applicationId,
  ],
  deleteDocumentImage: (applicationId: number) => [
    "deleteDocumentImage",
    applicationId,
  ],
  updateDocument: (applicationId: number) => ["updateDocument", applicationId],
  arsWithdrawalAccount: (applicationId: number) => [
    "arsWithdrawalAccount",
    applicationId,
  ],
  verifyBankAccount: (applicationId: number) => [
    "verifyBankAccount",
    applicationId,
  ],
  reviewApplication: (applicationId: number) => [
    "reviewApplication",
    applicationId,
  ],
});
