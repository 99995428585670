import { createQueryKeys } from "@lukemorales/query-key-factory";

export const platformsQueryKeys = createQueryKeys("platforms", {
  fetchPlatformsAccount: applicationId => [
    "fetchPlatformsAccount",
    applicationId,
  ],
  addPlatformsAccount: applicationId => ["addPlatformsAccount", applicationId],
  updatePlatformsAccount: applicationId => [
    "updatePlatformsAccount",
    applicationId,
  ],
  deletePlatformsAccount: applicationId => [
    "deletePlatformsAccount",
    applicationId,
  ],
});
