import React, { useState } from "react";

import { deleteCookie, getCookie, setCookie } from "@earlypay/shared";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { instance } from "@apis/instance";

import { error } from "@utils/noti";

import { applicationsQueryKeys, queryClient } from "@/apis";

const Admin = () => {
  const navigate = useNavigate();
  const [userToken, setUserToken] = useState("");

  const tokenLogin = async () => {
    try {
      deleteCookie("earlypay-token", "/", ".epayloan.kr");
      setCookie("earlypay-token", userToken, 7, ".epayloan.kr");
      axios.defaults.headers.common["authorization"] = `token ${userToken}`;

      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });

      navigate("/");
    } catch (err) {
      if (err?.response?.status === 502) {
        error("현재 업데이트중입니다. 잠시후 다시 시도해주세요");
      }
      Object.assign(instance.defaults, { headers: { authorization: `` } });
      error("잘못된 토큰입니다.");
      navigate("/500");
    }
  };

  const exitAdmin = () => {
    localStorage.removeItem("EPTK");
    Object.assign(instance.defaults, {
      headers: { authorization: `` },
    });
    navigate("/");
  };

  return (
    <AdminBox>
      <div className="input-box">
        <h3>토큰을 입력해주세요</h3>
        <input value={userToken} onChange={e => setUserToken(e.target.value)} />
        <div>
          <button onClick={exitAdmin}>닫기</button>
          <button onClick={tokenLogin}>확인</button>
        </div>
      </div>
    </AdminBox>
  );
};

export default Admin;

const AdminBox = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .input-box {
    width: 80%;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px;
    h3 {
      margin-bottom: 10px;
      font-size: 14px;
    }
    input {
      width: 100%;
      padding: 10px;
      border-bottom: 1px solid red;
    }
    button {
      width: 50%;
      background: transparent;
      padding-top: 10px;
      cursor: pointer;
    }
  }
`;
