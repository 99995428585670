import { toast, ToastOptions } from 'react-toastify';

interface ToastifyType {
  position: string;
  autoClose: number;
  hideProgressBar: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
  draggable: boolean;
  progress: undefined;
  theme: string;
}

const option: ToastOptions<ToastifyType> = {
  position: 'top-center',
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  // newestOnTop: false,
  theme: 'colored',
};

const success = (text: string) => toast.success(text, { ...option });
const error = (text: string) => toast.error(text, { ...option });
const info = (text: string) => toast.info(text, { ...option });
const warning = (text: string) => toast.warning(text, { ...option });

export { success, error, info, warning };
